import React from 'react'
import Layout from '../components/layout-v2';


const Page = () => (

  <Layout title="Consulting Services">
    <section>
      <h3>Professional Services</h3>
      <div className="img-container text-center">
        <img className="img-responsive" src="/images/Professional-Services-LP-Diagram-2.png" alt="Professional Services"/>
      </div>
      <p className="section-subheading">Quantic Professional Services offers complete consulting for businesses of small, medium and large Fortune 500 corporations. Quantic Professional Service engagements starts with an assessment of your current IT environments, data storage, data backup and disaster recovery strategies.</p>

      <section>
        Quantic Professional Services offers complete consulting for businesses of small, medium and large Fortune 500 corporations. Quantic Professional
        Service engagements starts with an assessment of your current IT environments, data storage, data backup and disaster recovery strategies. Current
        practices are benchmarked against industry best practices to identify performance bottlenecks and opportunities for data storage, data backup, and
        disaster recovery improvement.
        <br/>
        <br/>
        Assigned consultant will determine the best storage and data protection solution to meets your exclusive requirements. He or she will recommend disaster
        recovery strategies to manage any discovered vulnerabilities and also provide recommendations for the most efficient use of your resources.
        <br/>
        <br/>

        Following your environment initial analysis, consultant will fully integrate the recommended solutions, including the development of policies and
        procedures, the installation of new software, and any necessary upgrades and migrations.
        <br/>
        <br/>
      </section>

      <h3>Managed Services</h3>
      <div className="img-container text-center">
        <img className="img-responsive" src="/images/managed-services-1.png" alt="Managed Services"/>
      </div>
      <p className="section-subheading">Managed Services are the best cost effective way to manage IT resources and network by a using a third party service provider.
        Managed Services typically include monitoring & managing IT infrastructure -servers, networks, storage, and security , with a strong commitment by a  detailed Service Level Agreement.</p>
      <section>
        Your data storage and backup requirements are growing exponentially and managing this necessary storage and backup infrastructure to meet both your
        application and compliance requirements can be extremely time consuming and costly.
        <br/>
        <br/>
        Outsource to Quantic for managing your storage, backup and data protection environment while meeting your SLA/OLA within assigned budget and time.
        <br/>
        <br/>
        We provide Onsite/Off-site resources to manage your storage and backup environments. We conduct routine health check of the environment and provide
        comprehensive reports on regular basis. We are committed 100% to your SLA/OLA requirements.
        <br/>
        <br/>
        Outsource your data storage and data protection environment to Quantic. We will make sure to meet your SLA/OLA within assigned budget and time.
        <br/>
        <br/>
        <ul>
          <li>Predictive Modeling</li>
          <li>Data Tuning Optimization</li>
          <li>Business Intelligence & Reporting</li>
          <li>Data Management</li>
          <li>Quality Assurance and Data Integrity</li>
          <li>Data Integration</li>
          <li>Data Mining</li>
          <li>Internet of Things</li>
          <li>Cloud Solutions</li>
        </ul>

      </section>
    </section>
  </Layout>
);

export default Page;
